import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import QrPreview from '../Common/QrPreview';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { enviarRecetaElectronica } from '../../services/RecetaElectronica/RecetaElectronica';
import { useTheme } from '@emotion/react';
import { isMobile as isMobileFn } from '../../@iosper/utils/DeviceDetector';
import ButtonBack from '../Breadcrumb/ButtonBack';
import { Warning as WarningIcon } from '@mui/icons-material';

const customStyle = {};

const DefaultPropsTextField = {
  size: 'small',
  fullWidth: true,
  disabled: true,
  variant: 'filled',
};

const CssTextField = styled(TextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    '& .MuiInputBase-formControl': {
      background: ' rgba(0, 0, 0, 0.01)',
      '& .MuiInputBase-input': {
        color: 'inherit',
        fontWeight: 'bold',
        fontSize: '13px',
        // '-webkit-text-fill-color': theme.palette.text.primary,
        WebkitTextFillColor: theme.palette.text.primary,
      },
      '&:before': {
        borderBottom: '1px dotted rgba(0, 0, 0, 0.22)',
      },
    },
    '& .MuiInputLabel-root': { color: 'inherit' },
  },
}));

export const RecetaMedicaGenerada = () => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = isMobileFn() || !matches;

  const {
    afiliado,
    recetaElectronica,
    estado,
    rpList,
    tipoTratamiento,
    idsRecetas, //todas
    idsRecetasAsociadas, //solo ids de asociadas (no tiene id de la consultada)
    profesional,
    urlReporte,
    esConsulta,
  } = history?.location?.state?.receta || {};

  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [successCorreo, setSuccessCorreo] = useState('');
  //const [urlReporte, setUrlReporte] = useState("");
  const handlerEnviarCorreo = async (correo) => {
    setLoadingEnviar(true);
    setSuccessCorreo('');
    try {
      const res = await enviarRecetaElectronica({
        idsRecetas: idsRecetas,
        correo: correo,
      });

      setSuccessCorreo('Receta electrónica enviada al correo: ' + correo);
      setLoadingEnviar(false);
    } catch (e) {
      setLoadingEnviar(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <ButtonBack />

      <Grid item xs={12}>
        <Card sx={{ paddingX: { xs: 0, sm: 1 } }}>
          <CardContent style={customStyle}>
            <Box
              display={'flex'}
              direction='row'
              justifyContent={esConsulta ? 'center' : 'flex-start'}
              alignItems='center'
              mb={2}
              width={'100%'}
            >
              <Typography variant='h5' component={'span'}>
                {esConsulta && (<>Consulta de Receta Electrónica</>)}
                {!esConsulta && (<>Receta Electrónica generada</>)}
              </Typography>
              {!esConsulta && (
                <CheckCircleIcon fontSize='small' color='primary' sx={{ ml: 2 }} />
              )}
            </Box>

            <Grid container spacing={1} rowSpacing={1} gap={1}>
              {recetaElectronica && !esConsulta && (
                <Grid item xs={12}>
                  <QrPreview
                    showDownload={true}
                    showPrint={true}
                    urlReporte={urlReporte}
                    nombreReporte={'Receta_electrónica_iosper_' + recetaElectronica.numero}
                    qrValue={JSON.stringify({ codigo: recetaElectronica.numero })}
                    onEnviarCorreo={handlerEnviarCorreo}
                    showMail={true}
                    correo={afiliado.correo}
                    loadingEnviar={loadingEnviar}
                    successCorreo={successCorreo}
                  />
                </Grid>
              )}

              {recetaElectronica.domicilioAfiliado && (
                <Grid item xs={12}>
                  <div
                    className='container'
                    style={{
                      backgroundColor: '#FFF4E5',
                      borderRadius: '10px',
                      padding: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: recetaElectronica && !esConsulta ? '-20px' : '0px',
                      paddingBottom: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <WarningIcon
                      style={{
                        color: '#FF9800',
                        marginRight: '10px',
                        fontSize: '24px',
                      }}
                    />
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <p
                          style={{
                            color: '#804000',
                            margin: 0,
                            fontSize: '14px',
                            textAlign: isMobile ? 'justify' : 'left',
                          }}
                        >
                          La receta generada es de <strong>Venta Controlada/Archivada</strong> y
                          deberá ser impresa (solo una copia), firmada y sellada por el prescriptor,
                          para poder ser presentada en la farmacia, como lo requiere el Ministerio
                          de Salud de la Provincia de Entre Ríos.
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box
                  px={{ xs: 1, sm: 2 }}
                  py={1}
                  sx={{
                    borderRadius: { xs: 0, sm: '4px' },
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    background: 'rgb(241 241 241 / 35%)',
                  }}
                >
                  <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={customStyle}>
                        Receta Electrónica
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      {recetaElectronica && (
                        <FormControl fullWidth={true} variant='standard'>
                          <CssTextField
                            {...DefaultPropsTextField}
                            id='numeroReceta'
                            label='Número'
                            defaultValue={`${recetaElectronica.numero}`}
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='plan'
                          label='Plan'
                          defaultValue={`${recetaElectronica.planDescripcion}`}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='estado'
                          label='Estado'
                          defaultValue={`${estado?.descripcion}`}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='prescripcion'
                          label='Prescripción'
                          defaultValue={`${recetaElectronica.fechaPrescripcion}`}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='fechaInicio'
                          label='Inicio Vigencia'
                          defaultValue={`${recetaElectronica.fechaInicioVigenciaFormateada}`}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='vencimiento'
                          label='Vencimiento'
                          defaultValue={`${recetaElectronica.vencimientoFormateado}`}
                        />
                      </FormControl>
                    </Grid>
                    {estado?.codigo === '2' && (
                      <>
                        <Grid item xs={6} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <CssTextField
                              {...DefaultPropsTextField}
                              id='fechaEntregada'
                              label='Fecha entrega'
                              defaultValue={`${recetaElectronica.fechaEstado}`}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <CssTextField
                              {...DefaultPropsTextField}
                              id='farmacia'
                              label='Farmacia'
                              defaultValue={`${recetaElectronica.farmacia}`}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6} md={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='tipoTratamiento'
                          label='Tipo de Tratamiento'
                          defaultValue={`${tipoTratamiento.descripcion} ${recetaElectronica.periodo != null ? recetaElectronica.periodo : ''}`}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='tipoReceta'
                          label='Tipo de Receta'
                          defaultValue={`${recetaElectronica.tipoRecetaElectronicaDescripcion}`}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  px={{ xs: 1, sm: 2 }}
                  py={1}
                  sx={{
                    borderRadius: { xs: 0, sm: '4px' },
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    background: 'rgb(241 241 241 / 35%)',
                  }}
                >
                  <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={customStyle}>
                        Afiliado
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='documentoAfiliado'
                          label='Documento'
                          defaultValue={`(${afiliado.tipoDocumentoDescripcion}) ${afiliado.numeroDocumentoFormateado}`}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {afiliado && (
                        <FormControl fullWidth={true} variant='standard'>
                          <CssTextField
                            {...DefaultPropsTextField}
                            id='afiliado'
                            label='Apellido y Nombre'
                            defaultValue={`${afiliado.apellido}, ${afiliado.nombre}`}
                          />
                        </FormControl>
                      )}
                    </Grid>
                    {recetaElectronica?.domicilioAfiliado && (
                      <>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <CssTextField
                              {...DefaultPropsTextField}
                              id='telefono'
                              label='Teléfono'
                              defaultValue={`${recetaElectronica?.telefonoAfiliado}`}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth={true} variant='standard'>
                            <CssTextField
                              {...DefaultPropsTextField}
                              id='domicilio'
                              label='Domicilio'
                              defaultValue={`${recetaElectronica?.domicilioAfiliado}`}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  px={{ xs: 1, sm: 2 }}
                  py={1}
                  sx={{
                    borderRadius: { xs: 0, sm: '4px' },
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    background: 'rgb(241 241 241 / 35%)',
                  }}
                >
                  <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={customStyle}>
                        Prescripciones
                      </Typography>
                    </Grid>

                    {rpList.map((rp, idx) => {
                      const colPracticas = rpList.length === 1 ? 12 : rpList.length === 2 ? 6 : 4;
                      return (
                        <Grid item xs={12} md={colPracticas} key={idx}>
                          <FormControl
                            sx={{ marginTop: '0px' }}
                            key={idx}
                            fullWidth={true}
                            variant='standard'
                          >
                            <CssTextField
                              {...DefaultPropsTextField}
                              id='rp1'
                              label={`RP${idx + 1}`}
                              defaultValue={`${rp?.medicamento?.drogas || '-'} ${rp.medicamento.presentacion
                                }, cantidad: ${rp.medicamento.cantidad}(${rp.cie10.descripcion} (${rp.cie10?.codigo
                                }))`}
                              multiline
                            />
                          </FormControl>
                        </Grid>
                      );
                    })}

                    <Grid item xs={12}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='indicaciones'
                          label='Indicaciones'
                          defaultValue={`${recetaElectronica.observacion}`}
                          multiline
                          maxRows={4}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  px={{ xs: 1, sm: 2 }}
                  py={1}
                  sx={{
                    borderRadius: { xs: 0, sm: '4px' },
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    background: 'rgb(241 241 241 / 35%)',
                  }}
                >
                  <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={customStyle}>
                        Prescriptor
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='profesionPrescriptor'
                          label='Profesión'
                          defaultValue={`${profesional.profesion} (Matrícula ${profesional.matricula})`}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='apellidoYNombrePrescriptor'
                          label='Apellido y Nombre'
                          defaultValue={`${profesional.descripcion}`}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='telefonoPrescriptor'
                          label='Teléfono'
                          defaultValue={`${profesional?.telefono || '-'}`}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth={true} variant='standard'>
                        <CssTextField
                          {...DefaultPropsTextField}
                          id='domicilioPrescriptor'
                          label='Domicilio'
                          defaultValue={`${profesional?.domicilio}`}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {idsRecetasAsociadas.length > 0 && (
                <Grid item xs={12}>
                  <Box
                    px={{ xs: 1, sm: 2 }}
                    py={1}
                    sx={{
                      borderRadius: { xs: 0, sm: '4px' },
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                      background: 'rgb(241 241 241 / 35%)',
                    }}
                  >
                    <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                      <Grid item xs={12}>
                        <Typography variant='h6' style={customStyle}>
                          Otras recetas del tratamiento
                        </Typography>
                      </Grid>

                      <Box display={'flex'} gap={1} mx={2} my={1}>
                        {idsRecetasAsociadas.map((recetaAsociada, idx) => {
                          return (
                            <Button key={idx} variant='outlined'>
                              {recetaAsociada.numero}
                            </Button>
                          )
                        })}
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>

            <Typography
              variant='h6'
              color='textSecondary'
              component='p'
              mt={3}
              textAlign={'center'}
            >
              Receta firmada electrónicamente
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
